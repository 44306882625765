<template>
  <div class="design"> 
    <div class="design-main">
      <div class="content">
        <div class="desc">
          <div class="title">{{cmsHelp.title}}</div>
         <div class="text">
          <div class="r" id="zongjingli">
           <!-- {{cmsHelp.createDate}}    <br /> -->
            </div>
          </div>
          <div class="text" v-html="cmsHelp.yzCmsHelpData.cmsAskDataContent">              
            
          </div>
          <div class="text">
          <div class="r" id="zongjingli">

            <div class="btns">
          <div v-if="hasLogin == 0">
           <a href="https://www.huixianggongye.com//account/registerUser?yzType=1" target="_blank"> 联系我们</a>
          </div>
          <div @click="sendMessage();" v-if="hasLogin == 1">
            <a>联系我们</a>   
          </div>
        </div> 

          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
 data() {
    return {
      hasLogin: false,
      cmsHelp: { "yzCmsHelpData": {"cmsAskDataContent": ""}}

    };
  },methods: {
    
    sendMessage() {

      this.$notify({
          title: '申请成功',
          message: '我们已收到您的需求，我们的工作人员会与您联系或请拨打我们的服务电话0431-8088 7187，我们将竭诚为您服务！',
          type: 'success'
        });
      this.$api.get("https://www.huixianggongye.com/f/lianxiwomenmessage.html?url="+window.location.href).then((res) => {console.lorg(res);    });
    },
    getCMSHelpDetail() {
      this.loading = true;
      this.$api
        .get("/api/v1/getYzCmsHelpView", {
          params: {
            id: `${this.$route.query.id}`
          },
        })
        .then((res) => {
          this.loading = false;
           this.cmsHelp=res.data;
         if (res.code != 1) {
            this.$message({
            message: res.message,
            type: res.code == 1 ? "success" : "error",
            duration: 1500,
          });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.$api.get("/api/v1/loginUserCheck").then((res) => {
      this.hasLogin = res.code;
      if (res.data) {
        this.userInfo = res.data;
        if (res.data.roleCode) {
          if (res.data.roleCode.indexOf("member") >= 0) {
            //客户
            this.role = 1;
          } else if (res.data.roleCode.indexOf("shoper") >= 0) {
            //服务商
            this.role = 2;
          } else if (
            res.data.roleCode.indexOf("member") >= 0 &&
            res.data.roleCode.indexOf("shoper") >= 0
          ) {
            //多重角色
            this.role = 3;
          }
        }
        this.userInfo.roleType = this.role;
        this.$store.commit("setUserInfo", this.userInfo);
        this.$storage.setItem("userInfo", this.userInfo);
      }
    });
   setTimeout(() => {
      this.$store.commit("anime");
    }, 300);   
    this.$parent.finish ? this.$parent.finish() : null;
     this.getCMSHelpDetail();
  }
};
</script>
<style lang="scss">

.btns {
                width: 100%;
                text-align: right;
                z-index: 99;

                div a{
                    display: inline-block;
                    position: relative;
                    width: 8vw;
                    height: 3vw;
                    text-align: center;
                    line-height: 3vw;
                    color: white;
                    font-size: 1.1vw;
                    font-weight:bolder;
                    margin: 0 50px;
                    border-radius: 100px;
                    background-color: fade-out($color:#014d91 , $amount: 0.1);

                    &:nth-child(2) {
                        background-color: fade-out($color:#014d91, $amount: 0.1);
                    }

                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    transition: 0.3s all;

                    &:hover {
                        background-color: fade-out($color:#151762, $amount: 0);

                        &:nth-child(2) {
                            background-color: fade-out($color:#151762, $amount: 0);
                        }
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 150px;
                        opacity: 0.8;
                    }
                }
            }
.design {
  .banner {
    img {
      width: 100%;
      display: block;
    }
  }
  .design-main {
    margin: 50px auto;
    .desc {
      border: solid 1px #f2f2f2;
    }
    .title {
      background-color: #f2f2f2;
      line-height: 50px;
      font-size: 22px;
      text-align: center;
    }
    .text {
      padding: 30px;
      font-size: 16px;
      line-height: 2;
      .r {
        text-align: right;
      }
    }
  }
}
</style>